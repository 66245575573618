<template>
  <div>
    <swiper :options="swiperOptions">
      <swiper-slide class="tw_trading_information" >
        <div v-for="schema in schemas" :key="schema.key" @contextmenu.prevent="copyValue">
          <div>
            <div class="label">{{getLabel(schema)}}</div>
            <div v-if="schema.dataType === 'Date'" class="value">{{getValue(schema) | utcOffsetDateFormat}}</div>
            <div v-else-if="schema.dataType === 'Quantity'" class="value">{{getQuantity(schema)}}</div>
            <div v-else-if="schema.dataType === 'Currency'" class="value">{{getCurrency(schema)}}</div>
            <div v-else-if="schema.dataType === 'TradingFlowId'" class="value">{{getTradingFlowId(schema)}}</div>
            <div v-else class="value">{{getValue(schema)}}</div>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-scrollbar" slot="scrollbar"></div>
    </swiper>
  </div>
</template>

<script>
import _ from 'lodash';
import { formatQuantity, formatCurrency } from '@/utils/searchUtil.js';
import tradingInformationSchema from '@/dictionaries/tradingInformationSchema.json';

export default {
  name: 'TwTradingInformation',
  props: {
    items: Object,
    schema: Array,
    itemCopyable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      schemas: this.schema || tradingInformationSchema,
      swiperOptions: {
        slidesPerView: "auto",
        freeMode: true,
        scrollbar: {
          el: ".swiper-scrollbar",
          hide: true,
          draggable: true,
        },
        grabCursor: true,
      }
    }
  },
  computed: {
    getLabel() {
      return schema => {
        return schema.label;
      };
    },
    getValue() {
      return schema => {
        return this.items[schema.key];
      };
    },
    getQuantity() {
      return schema => {
        return formatQuantity(this.items[schema.key], this.items[schema.target]);
      };
    },
    getCurrency() {
      return schema => {
        const value = this.items[schema.key];
        const target = this.items[schema.target];
        
        if (!target || !this.$store.state.systemProperties.SYS_MST_CURRENCY) {
          return formatCurrency(value);
        } 
        const rule = _.find(this.$store.getters.getSysMstCurrency, {alphabeticCode: target});
        if (!rule) {
          return formatCurrency(value);
        }
        // 小数点以下は通貨の指定桁で切り捨て0埋め
        return formatCurrency(value, rule.symbol, rule.minorUnit);
      };
    },
    getTradingFlowId() {
      return schema => {
        const tradingId = this.items[schema.key];
        const flowId = this.items.tradingFlowId;
        if (!tradingId && !flowId) {
          return '';
        }
        else {
          return `${tradingId}/${flowId}`;
        }
      };
    }
  },
  methods: {
    copyValue(event) {
      if(!this.itemCopyable) return;
      const target = event.target;
      let targetText = '';
      if (target.className === 'label') targetText = target.nextElementSibling.innerText;
      else if (target.className === 'value') targetText = target.innerText;
      else if (target.children.length === 1) {
        targetText = Array.from(target.children[0].children).find(el => el.className === 'value')?.innerText;
      } else if (target.children.length === 2) {
        targetText = Array.from(target.children).find(el => el.className === 'value')?.innerText;
      }
      if(!targetText) return;
      this.$copyText(targetText).then(() => {
        this.$message({
          message: 'Copied!',
          type: 'success'
        });
      }, () => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.tw_trading_information {
  width: auto;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  > div {
    border-left: 1px solid $color_gray_300;
    padding: 6px 24px 0;
    height: 55px;
    white-space: nowrap;
    flex-shrink: 0;

    &:first-child {
      border: none;
    }

    .label {
      font-size: 12px;
      line-height: 18px;
      color: $color_gray_600;
      margin: 0 0 4px;
      text-align: center;
    }

    .value {
      font-size: 14px;
      line-height: 20px;
      color: $color_black;
      text-align: center;
    }
  }
}

::v-deep .swiper-container {
  padding-left: 34px;
  padding-right: 60px;
}
</style>
