<template>
  <table class="tw_from_to">
    <tr>
      <th>from</th>
      <th class="ship" rowspan="2">
        <img src="@/assets/images/ship.png">
      </th>
      <th>to</th>
    </tr>
    <tr>
      <td>
        <el-tooltip v-if="from.fromCompanyName || from.fromSectionName" placement="top" popper-class="mcu" :tabindex="-1">
          <div slot="content">{{from.fromCompanyName + ' ' + from.fromSectionName}}</div>
          <span><figure class="avatar table"><img :src="from.fromCompanyIcon || '/images/customer.png'"></figure><span style="display: block;width: 74px;overflow: hidden;white-space:nowrap;text-overflow: ellipsis;">{{from.fromCompanyShortName}}</span></span>
        </el-tooltip>
        <div v-else>
          <span><figure class="avatar table"><img :src="from.fromCompanyIcon || '/images/customer.png'"></figure><span style="display: block;width: 74px;overflow: hidden;white-space:nowrap;text-overflow: ellipsis;">{{from.fromCompanyShortName}}</span></span>
        </div>
      </td>
      <td>
        <el-tooltip v-if="to.toCompanyName || to.toSectionName" placement="top" popper-class="mcu" :tabindex="-1">
          <div slot="content">{{to.toCompanyName + ' ' + to.toSectionName}}</div>
          <span><figure class="avatar table"><img :src="to.toCompanyIcon || '/images/customer.png'"></figure><span style="display: block;width: 74px;overflow: hidden;white-space:nowrap;text-overflow: ellipsis;">{{to.toCompanyShortName}}</span></span>
        </el-tooltip>
        <div v-else>
          <span><figure class="avatar table"><img :src="to.toCompanyIcon || '/images/customer.png'"></figure><span style="display: block;width: 74px;overflow: hidden;white-space:nowrap;text-overflow: ellipsis;">{{to.toCompanyShortName}}</span></span>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'TwFromTo',
  props: {
    from: Object,
    to: Object
  },
  data() {
    return {

    };
  },
  computed: {

  },
};
</script>

<style lang="scss">
  .tw_from_to {
    border-collapse: collapse;
    border-spacing: 0;

    th, td {
      padding: 0;
    }

    th {
      height: 17px;
      font-weight: 500;
      font-size: 10px;
      line-height: 11px;
      color: $color_gray_600;
      vertical-align: middle;
      text-align: center;
    }

    .ship {
      width: 48px;

      img {
        display: block;
        width: 25px;
        margin: 0 auto;
      }
    }

    td {
      vertical-align: middle;

      span {
        display: flex;
        align-items:center;
        font-size: 12px;
        line-height: 20px;
        color: $color_black;

        figure.avatar.table {
          margin-right: 4px;
        }
      }
    }
  }
</style>
